import {Link} from 'gatsby'
import React from 'react'
import {buildImageObj, getStudentWorkUrl} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import '../styles/student-work-archive.css'

function StudentWorkArchive (props) {

  console.log(props);

  return (

    <div className="student-work-archive-item width-6-12-m width-3-12">

        {props.images[0] && props.images[0].asset && (
          <div className="image-holder">
            <Link to={getStudentWorkUrl(props.year, props.slug.current)}>
            <img
              src={imageUrlFor(buildImageObj(props.images[0]))
                .width(800)
                .height(800)
                .fit('crop')
                .url()}
              alt={props.images[0].alt}
              title={props.title}
            />
            </Link>

          </div>
        )}

    </div>
  )
}

export default StudentWorkArchive