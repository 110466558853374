import React from 'react'
import StudentWorkArchive from './student-work-archive'

import '../styles/student-work-archive-list.css'

function StudentWorkArchiveList (props) {

  console.log(props)

  return (
    <div className="student-work-archive-list wrapper">
      {props.title && <h3>{props.title}</h3>}
        
      <div className="grid student-work-archive-holder">
        {props.nodes &&
          props.nodes.map(node => (
              <StudentWorkArchive {...node} />
          ))}
      </div>
</div>
  )
}

StudentWorkArchiveList.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default StudentWorkArchiveList